<template>
  <Gallery category="index"/>

</template>

<script>
import Gallery from '@/components/SingleGallery.vue'

export default {
  name: 'App',
  components: {
    Gallery
  }
}
</script>
